import { Typography } from '@core/UI/Typography';

import ResultsChart from '../ResultsChart';

import * as UI from './Results.styles';
import { IResultsProps } from './types';

const Results = (props: IResultsProps) => {
  const { onClose, poll } = props;

  return (
    <>
      <Typography type="h3">
        Спасибо! Ваш голос учтен.
      </Typography>
      <Typography color="manatee" type="p1">
        Результаты опроса
      </Typography>

      <ResultsChart poll={poll} />

      <UI.CloseButton
        onClick={onClose}
      >
        Закрыть
      </UI.CloseButton>
    </>
  );
};

export default Results;
