import styled, { css } from 'styled-components';

import { Typography } from '@core/UI/Typography';

export const ModalStyles = css`
  top: 120px;
  border-radius: 8px;
`;

export const ModalHeader = styled(Typography).attrs({ type: 'h1' })`
  margin: 0 32px 20px 0;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;

  button + button {
    margin-left: 8px;
  }
`;
