import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { Typography } from '@core/UI/Typography';
import CloseIconCore from '@core/assets/icons/close.svg';
import colors from '@core/constants/colors';
import { BREAKPOINTS, PAGE_PADDINGS } from '@core/constants/styles';

import pollIconUrl from '../../assets/poll.svg?url';

export const Container = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  transition: all .2s;
  width: calc(100% - ${PAGE_PADDINGS.MOBILE * 2}px);

  @media ${BREAKPOINTS.TABLET} {
    width: 383px;
  }

  ${({ styles }) => styles}
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  column-gap: 4px;
  align-items: center;
  padding: 12px 14px 12px 20px;
  background-color: ${colors.blue};
  border-radius: 8px;
  box-shadow: 2px -2px 8px rgba(0, 0, 0, 0.1), -8px -4px 30px rgba(50, 92, 146, 0.2), 8px 10px 30px rgba(50, 92, 146, 0.1);
`;

export const PollIcon = styled.div`
  background-image: url(${pollIconUrl});
  width: 31px;
  height: 34px;
`;

export const TextContent = styled.div`
  padding: 3px 0px;
  margin-left: 14px;
`;

export const PollName = styled(Typography).attrs({ color: 'white', ellipsisMaxLines: 2, type: 'p2' })`
  height: 40px;
  cursor: default;
`;

export const CloseIcon = styled(CloseIconCore)`
  color: ${colors.cadetBlue};
  width: 14px;
  height: 14px;
  align-self: start;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  flex-shrink: 0;
  display: flex;
  padding: 8px;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    color: ${colors.cadetBlue};
    transform: scale(0.6);
  }
`;

export const ButtonLink = styled.button`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
  text-decoration: underline;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;
