import styled, { css } from 'styled-components';

import colors from '@core/constants/colors';

import { ILabelStylesProps } from './types';

export const Checkmark = styled.span`
  display: inline-block;
  height: 18px;
  width: 18px;
  padding: 3px 2px 3px 3px;
  border: 1px solid ${colors.manatee};
  border-radius: 4px;
  transition: all .15s;

  svg {
    display: block;
    color: transparent;
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${Checkmark} {
    background: ${colors.chambrayBlue};
    border-color: ${colors.chambrayBlue};

    svg {
      color: ${colors.white};
    }
  }
`;

export const Label = styled.label<ILabelStylesProps>`
  display: flex;
  column-gap: 12px;
  cursor: pointer;
  user-select: none;
  color: ${colors.riverBlue};

  &:hover ${Checkmark} {
    border: 1px solid ${colors.chambrayBlue};
  }

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    color: ${colors.cadetBlue};

    ${Checkmark},
    &:hover ${Checkmark} {
      border-color: currentColor;
    }
  `}
`;
