import { useState, useEffect } from 'react';

import { isBrowser } from '@core/utils/isBrowser';

const getInitialValue = (query: string, initialValue: boolean) => {
  if (typeof initialValue === 'boolean') {
    return initialValue;
  }

  if (isBrowser() && 'matchMedia' in window) {
    return window.matchMedia(query).matches;
  }

  return false;
};

export const useMedia = (query: string, initialValue = false) => {
  const [matches, setMatches] = useState(getInitialValue(query, initialValue));

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const media = window.matchMedia(query);
    setMatches(media.matches);

    const listener = (e: MediaQueryListEvent) => setMatches(e.matches);

    media.addEventListener('change', listener);

    return () => {
      media.removeEventListener('change', listener);
    };
  }, [query]);

  return matches;
};
