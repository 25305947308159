import Footer from '../Footer';
import FooterPublic from '../FooterPublic';
import Header from '../Header';
import Polls from '../Polls';

import * as UI from './Template.styles';
import { ITemplateProps } from './types';

const Template = (props: ITemplateProps) => {
  const { isPublic = false, hasPolls = true, children } = props;

  return (
    <UI.Container>
      <Header isPublic={isPublic} />
      <UI.Main>{children}</UI.Main>
      {isPublic ? <FooterPublic /> : <Footer />}
      {hasPolls && <Polls isPublic={isPublic} />}
    </UI.Container>
  );
};

export default Template;
