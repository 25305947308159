export const LINKS = {
  GOVERNMENT: 'http://government.ru/',
  NP_MAGISTRAL_PLAN: 'https://национальныепроекты.рф/projects/kompleksnyy-plan-modernizatsii-i-rasshireniya-magistralnoy-infrastruktury',
  NP_SAFE_ROADS: 'https://bkdrf.ru/',
  NP_URBAN_ENVIRONMENT: 'https://национальныепроекты.рф/projects/zhile-i-gorodskaya-sreda',
  MINSTROY: 'https://minstroyrf.gov.ru/',
  MINTRANS: 'https://mintrans.gov.ru/',
  STROYCOMPLEX: 'https://stroi.gov.ru/',
  M12: '/video-archive',
};
