import CheckmarkIcon from '@core/assets/icons/checkmark.svg';

import { Typography } from '../Typography';

import * as UI from './Checkbox.styles';
import { ICheckboxProps } from './types';

const Checkbox = (props: ICheckboxProps) => {
  const { className, checked, children, onChange, disabled = false } = props;

  return (
    <UI.Label className={className} disabled={disabled}>
      <UI.Input
        checked={checked}
        disabled={disabled}
        type="checkbox"
        onChange={() => onChange(!checked)}
      />
      <UI.Checkmark>
        <CheckmarkIcon />
      </UI.Checkmark>

      <Typography
        color="inherit"
        type="p2"
      >
        {children}
      </Typography>
    </UI.Label>
  );
};

export default Checkbox;
