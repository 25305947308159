import styled, { css } from 'styled-components';

import * as InputStyles from '@core/UI/Input/Input.styles';
import colors from '@core/constants/colors';

export const RadioInput = styled.input`
  display: none;
`;

export const RadioCircle = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-right: 12px;
  border: 1px solid ${colors.riverBlue};
  border-radius: 50%;

  ${RadioInput}:checked + & {
    border-color: ${colors.chambrayBlue};
  }

  &::after {
    visibility: hidden;
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${colors.chambrayBlue};
  }

  ${RadioInput}:checked + &::after {
    visibility: visible;
  }
`;

export const RadioLabel = styled.label`
  display: flex;
  color: ${colors.riverBlue};
  cursor: pointer;
  flex: 1;

  & + & {
    margin: 0 0 0 16px;
  }

  &:hover > ${RadioCircle} {
    border-color: ${colors.chambrayBlue};
  }
`;

export const RadioText = styled.span`
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
`;

export const RadiosContainer = styled.div<{ isVertical: boolean }>`
  display: flex;
  flex-direction: row;

  ${({ isVertical }) => isVertical && css`
    flex-direction: column;

    ${RadioLabel} + ${RadioLabel} {
      margin: 16px 0 0 0;
    }
  `}
`;

export const RadioHeadingLabel = styled(InputStyles.InputLabel)``;

export const Wrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  padding-bottom: 24px;

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;

    ${RadioHeadingLabel},
    ${RadioLabel} {
      cursor: not-allowed;
      color: ${colors.cadetBlue};
    }

    ${RadioCircle},
    ${RadioInput}:checked + ${RadioCircle},
    ${RadioLabel}:hover > ${RadioCircle} {
      border-color: ${colors.cadetBlue};
      &::after {
        background-color: ${colors.cadetBlue};
      }
    }
  `}
`;

export const { ErrorMessage } = InputStyles;
