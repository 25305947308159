import http from '@core/services/Http';
import { getBackendUrl } from '@core/utils/env';

import { IRefreshJWTPayload, IResponseRefreshToken } from './types';

export const apiLogout = async (payload: { token: string }) => {
  await http.delete('session', payload);
};

// Используется в middleware. При использовании http (axios) ошибка (adapter is not a function).
export const fetchRefreshJWT = async (payload: IRefreshJWTPayload): Promise<IResponseRefreshToken> => {
  const response = await fetch(`${getBackendUrl()}session/refresh`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return response.json();
};
