import styled from 'styled-components';

import { Button } from '@core/UI/Button';
import CheckboxCore from '@core/UI/Checkbox';
import CoreRadio from '@core/UI/Radio';
import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

export const Header = styled(Typography).attrs({ type: 'h3' })`
  margin-right: 24px;
`;

export const Radio = styled(CoreRadio)`
  margin-top: 24px;
`;

export const CheckboxGroup = styled.div`
  margin: 24px 0 24px;
`;

export const Checkbox = styled(CheckboxCore)`
  margin-bottom: 16px;
`;

export const ErrorMessage = styled(Typography).attrs({ type: 'p2', color: 'bloodRed' })`
  padding: 12px 16px;
  background: rgba(255, 109, 109, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;
`;

export const Controls = styled.div`
  display: grid;
  column-gap: 24px;
  grid-template-columns: 93px max-content;

  button {
    font-size: 14px;
    line-height: 20px;
    min-width: unset;
  }

  @media ${BREAKPOINTS.TABLET} {
    grid-template-columns: 153px 190px;
  }
`;

export const HidePollButton = styled(Button).attrs({ type: 'transparent' })`
  color: ${colors.chambrayBlue};
`;
