import styled from 'styled-components';

import colors from '@core/constants/colors';
import { BREAKPOINTS, MAX_LAYOUT_WIDTH, PAGE_PADDINGS } from '@core/constants/styles';

export const FooterWrapper = styled.footer`
  flex-shrink: 0;
  border-top: 1px solid ${colors.cadetBlue};
  background-color: ${colors.white};

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: flex;
    justify-content: center;
  }
`;

export const FooterContainer = styled.div`
  max-width: ${MAX_LAYOUT_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${PAGE_PADDINGS.MOBILE}px ${PAGE_PADDINGS.MOBILE}px;

  @media ${BREAKPOINTS.TABLET} {
    flex-direction: row;
    padding: 16px ${PAGE_PADDINGS.TABLET}px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    width: ${MAX_LAYOUT_WIDTH}px;
  }
`;

export const ExternalLink = styled.a`
  height: 48px;
  margin-top: 32px;
  flex-basis: 18%;

  @media ${BREAKPOINTS.TABLET} {
    flex-basis: auto;
    height: 40px;
    margin-top: 0;
  }

  svg {
    height: 100%;
  }
`;

export const LinksContainer = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 32px;

  ${ExternalLink} + ${ExternalLink} {
    margin-left: 20px;
  }

  @media ${BREAKPOINTS.TABLET} {
    margin-bottom: 0;

    ${ExternalLink} + ${ExternalLink} {
      margin-left: 24px;
    }
  }
`;

export const Contacts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${BREAKPOINTS.TABLET} {
    align-items: flex-end;
  }
`;

export const PhoneNumber = styled.a`
  color: ${colors.cadetBlue};

  && {
    text-decoration: none;
  }
`;

export const Email = styled.a`
  color: ${colors.cadetBlue};
  font-weight: 300;
`;
