import { useEffect, useState } from 'react';

import { IToast } from '@eisgs/toast';

export const useToast = () => {
  const [toast, setToast] = useState<IToast>();

  useEffect(() => {
    import('@eisgs/toast').then((module) => setToast(module.toast));
  }, []);

  return toast;
};
