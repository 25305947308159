import styled, { FlattenSimpleInterpolation } from 'styled-components';

import colors from '@core/constants/colors';

export const Question = styled.div`
  margin-top: 24px;
`;

export const Variants = styled.div`
  margin-top: 24px;
  padding-left: 12px;
`;

export const Variant = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: auto auto;
  column-gap: 12px;
  row-gap: 8px;

  & + & {
    margin-top: 16px;
  }
`;

export const Percentage = styled.div<{ percent: number }>`
  height: 4px;
  grid-column: 1 / 3;
  border-radius: 8px;
  background: ${({
    percent,
  }) => `linear-gradient(to right, ${colors.lightGreen} ${percent}%, ${colors.softGrey} ${percent}% 100%)`};
`;

export const Container = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  ${({ styles }) => styles}
`;
