import Link from 'next/link';
import { useRouter } from 'next/router';

import { Typography } from '@core/UI/Typography';
import { ROUTES } from '@core/constants/routes';
import { isEqualPaths } from '@core/utils/isEqualPaths';

import { PAGES_TITLES } from '../../constants';

import * as UI from './Breadcrumbs.styles';

const Breadcrumbs = () => {
  const { pathname } = useRouter();

  const matchedRoute = (Object.keys(ROUTES) as Array<keyof typeof ROUTES>).find(
    (key) => isEqualPaths(pathname, ROUTES[key], { exact: true }),
  );

  return (
    <UI.Breadcrumbs>
      <Link href={ROUTES.LANDING}>
        <Typography color="white" tag="span" type="p2">
          stroi.gov.ru
        </Typography>
      </Link>
      {matchedRoute && PAGES_TITLES[matchedRoute] && (
        <>
          <Typography color="white" type="p2">
            /
          </Typography>
          <Typography color="white" type="p2">
            {PAGES_TITLES[matchedRoute]}
          </Typography>
        </>
      )}
    </UI.Breadcrumbs>
  );
};

export default Breadcrumbs;
