import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { selectJWTTokens } from '@features/authentication/store';

import { apiLogout } from '../api';

export const useLogout = () => {
  const { refresh } = useSelector(selectJWTTokens);

  return useCallback(async () => {
    if (refresh) {
      await apiLogout({ token: refresh });
    }
  }, [refresh]);
};
