import { useRouter } from 'next/router';
import type { MouseEvent, TouchEvent } from 'react';

import { Preloader } from '@eisgs/preloader';

import colors from '@core/constants/colors';

import * as UI from './Button.styles';
import { IButtonProps } from './types';

const getButtonElement = (type: IButtonProps['type']) => {
  switch (type) {
    case 'filled':
      return UI.ButtonFilled;
    case 'primary':
      return UI.ButtonPrimary;
    case 'secondary':
      return UI.ButtonSecondary;
    case 'transparent':
    default:
      return UI.ButtonTransparent;
  }
};

export const Button = (props: IButtonProps) => {
  const {
    children,
    onClick = () => {},
    htmlType = 'button',
    loading,
    color,
    disabled,
    className,
    href,
    type = 'filled',
    rightSide,
  } = props;

  const router = useRouter();

  const ButtonElement = getButtonElement(type);

  const handleClick = (event: MouseEvent | TouchEvent) => {
    if (disabled || loading) {
      return;
    }

    if (href) {
      router.push(href);
    } else {
      onClick(event);
    }
  };

  return (
    <ButtonElement
      $loading={loading}
      $type={type}
      className={className}
      color={color}
      disabled={disabled}
      type={htmlType}
      onClick={handleClick}
    >
      {loading && (
        <Preloader
          isAbsolute
          color={colors.white}
          size={24}
        />
      )}

      {!loading && (
        <>
          {children}
          {rightSide && <UI.RightSide>{rightSide}</UI.RightSide>}
        </>
      )}
    </ButtonElement>
  );
};
