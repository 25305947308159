import { ROUTES } from '@core/constants/routes';

const MATERIALS = 'Материалы';
const ANTI_CRISIS_MONITORING = 'Антикризисный мониторинг';
const NEWS = 'Новости';
const SVETOFORS = 'Светофоры';

export const PAGES_TITLES: Record<keyof typeof ROUTES, string> = {
  LANDING: '',
  PHOTOS: 'Фотографии',
  VIDEO_ARCHIVE: 'Видеоархив',
  LOGIN: 'Вход',
  NEWS_LIST: NEWS,
  NEWS_PAGE: NEWS,
  HARDCODE_NEWS_PAGE: NEWS,
  MAP: 'Карта',
  STRATEGY: 'О Стройкомплексе',
  SVETOFORS,
  SVETOFOR: SVETOFORS,
  SVETOFOR_DETAILING: SVETOFORS,
  DASHBOARD: SVETOFORS,
  NATIONAL_PROJECTS: 'Национальные проекты',
  INFRASTRUCTURE_MENU: 'Инфраструктурное меню',
  INVESTMENT_CONSTRUCTION_CYCLE: 'Инвестиционно-строительный цикл',
  MATERIALS,
  MATERIALS_HEADQUARTER: MATERIALS,
  MATERIALS_DISCUSSIONS: MATERIALS,
  MATERIALS_DISCUSSION: MATERIALS,
  MATERIALS_STUDY: MATERIALS,
  FEEDBACK: 'Обратная связь',
  ADMIN_PANEL: 'Панель администратора',
  ANTI_CRISIS_MONITORING,
  ANTI_CRISIS_MONITORING_HOUSING_POLICY: ANTI_CRISIS_MONITORING,
  ANTI_CRISIS_MONITORING_MORTGAGE: ANTI_CRISIS_MONITORING,
  ANTI_CRISIS_MONITORING_OFFERS: ANTI_CRISIS_MONITORING,
  ANTI_CRISIS_MONITORING_DEMAND: ANTI_CRISIS_MONITORING,
  ANTI_CRISIS_MONITORING_BUILDING_MATERIALS: ANTI_CRISIS_MONITORING,
  ANTI_CRISIS_MONITORING_SOURCES: ANTI_CRISIS_MONITORING,
  ENCYCLOPEDIA: 'Большая строительная энциклопедия',
};
