interface IOptions {
  exact?: boolean,
}

const isDynamicSegment = (segment: string) => segment[0] === '[' && segment.at(-1) === ']';

const splitPathSegments = (path: string) => path.replace(/^\/|\/$/g, '').split('/');

export const isEqualPaths = (actual: string, expected: string, options: IOptions = {}) => {
  const actualSegments = splitPathSegments(actual);
  const expectedSegments = splitPathSegments(expected);

  if (actualSegments.length !== expectedSegments.length && options.exact) {
    return false;
  }

  return expectedSegments.every((segment, i) => {
    const actualSegment = actualSegments[i];

    return isDynamicSegment(segment) || segment === actualSegment;
  });
};
