export const MAX_LAYOUT_WIDTH = 1360;

export const Z_INDEX_HEADER = 100;
export const Z_INDEX_LEFT_MENU = 100;
export const Z_INDEX_DRAG_HANDLE = 100;
export const Z_INDEX_MODAL = 9999;
export const Z_INDEX_POPUP = 1000;

export const BREAKPOINTS = {
  MOBILE_S: '(min-width: 320px)',
  MOBILE: '(min-width: 375px)',
  MOBILE_LG: '(min-width: 425px)',
  TABLET: '(min-width: 768px)',
  DESKTOP: '(min-width: 1024px)',
  DESKTOP_LG: '(min-width: 1440px)',
};

export const DEVICES = {
  MOBILE: '(min-width: 320px) and (max-width: 767px)',
  TABLET: '(min-width: 768px) and (max-width: 1439px)',
};

export const PAGE_PADDINGS = {
  MOBILE_S: 24,
  MOBILE: 24,
  MOBILE_LG: 24,
  TABLET: 40,
  DESKTOP: 40,
  DESKTOP_LG: 40,
};
