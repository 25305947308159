import Link from 'next/link';
import { useRouter } from 'next/router';
import { MouseEvent } from 'react';

import { scrollIntoElementIdView } from '@core/utils/scroll';

import * as UI from './NavLink.styles';
import { INavLinkProps } from './types';

const NavLink = (props: INavLinkProps) => {
  const { children, styles, href, activeStyles, onClick, scrollToAnchor, offsetScroll = 0, ...args } = props;

  const { pathname } = useRouter();
  const isActive = pathname === href;

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    onClick?.(event);

    if (!scrollToAnchor) {
      return;
    }

    const [path, hash] = href.split('#');

    if (hash && path === pathname) {
      event.preventDefault();

      scrollIntoElementIdView(hash, offsetScroll);
    }
  };

  return (
    <Link legacyBehavior passHref href={href} {...args}>
      <UI.NativeLink
        activeStyles={isActive ? activeStyles : undefined}
        styles={styles}
        onClick={handleClick}
      >
        {children}
      </UI.NativeLink>
    </Link>
  );
};

export default NavLink;
