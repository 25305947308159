import { css } from 'styled-components';

import { ModalStyles } from '@core/UI/Modal/Modal.styles';
import { BREAKPOINTS } from '@core/constants/styles';

export const PollModalStyles = css`
  ${ModalStyles};
  padding: 40px;

  @media ${BREAKPOINTS.TABLET} {
    width: 569px;
  }
`;
