import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

export const Breadcrumbs = styled.div`
  display: none;

  @media ${BREAKPOINTS.TABLET} {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: default;

    ${Typography} {
      font-size: 18px;
      letter-spacing: 0.12em;
      cursor: inherit;
    }
  }
`;
