import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';

import { IInputLabelStylesProps, IInputContainerStylesProps } from './types';

export const InputWrapper = styled.div`
  position: relative;
  padding-bottom: 24px;
`;

export const InputLabel = styled(Typography).attrs(() => ({ type: 'p3' }))<IInputLabelStylesProps>`
  word-break: break-word;
  color: ${({ $type = 'default' }) => ($type === 'default' ? colors.prussianBlue : colors.white)};
  margin-bottom: 4px;
`;

export const BasedInput = styled.input`
  width: 100%;
  padding: 12px 24px;
  border: 1px solid ${colors.cadetBlue};
  border-radius: 4px;
  outline: none;
  color: ${colors.riverBlue};
  font-size: 14px;
  line-height: 20px;

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${colors.cadetBlue};
  }
`;

export const Input = styled(BasedInput)`
  &:hover {
    cursor: pointer;
    border-color: ${colors.chambrayBlue};
  }

  &:focus {
    cursor: text;
    border-color: ${colors.riverBlue};
  }
`;

export const LandingInput = styled(BasedInput)`
  height: 44px;
  border: none;
  background: rgba(255, 255, 255, 0.2);
  color: ${colors.white};
`;

export const ErrorMessage = styled(Typography).attrs(({ type: 'p3' }))`
  position: absolute;
  bottom: 2px;
  color: ${colors.bloodRed};
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InputContainer = styled.div<IInputContainerStylesProps>`
  width: 100%;

  &.error {
    ${Input} {
      border-color: ${colors.bloodRed};
    }
  }

  &.disabled {
    ${InputLabel} {
      color: ${colors.cadetBlue};
    }

    ${Input} {
      color: ${colors.cadetBlue};
      background-color: ${colors.aliceBlue};
      border-color: ${colors.cadetBlue};
      cursor: not-allowed;
    }
  }

  ${({ styles }) => styles};
`;
