import { RefObject, useEffect } from 'react';

export const useClickOutside = (ref: RefObject<HTMLElement | null>, onClickOutside: () => void) => {
  const handleClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
