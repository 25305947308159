import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS, MAX_LAYOUT_WIDTH, PAGE_PADDINGS } from '@core/constants/styles';
import { hexToRGB } from '@core/utils/styles';

import minstroyIconUrl from './assets/minstroy.svg?url';

export const Wrapper = styled.div`
  background: linear-gradient(271.39deg, rgba(0, 21, 53, 0.2) 25.06%, ${hexToRGB(colors.black, 0)} 97.14%), ${colors.chambrayBlue};
  margin-top: 44px;
`;

export const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 27px ${PAGE_PADDINGS.MOBILE}px;

  @media ${BREAKPOINTS.TABLET} {
    gap: 32px;
    padding: 18px ${PAGE_PADDINGS.TABLET}px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    gap: 48px;
    width: ${MAX_LAYOUT_WIDTH}px;
    padding: 18px calc(2 * ${PAGE_PADDINGS.TABLET}px);
    margin: 0 auto;
  }
`;

export const MinstroyLogo = styled.a`
  background: url(${minstroyIconUrl}) no-repeat;
  background-size: cover;
  width: 121px;
  height: 46px;
  flex-shrink: 0;

  @media ${BREAKPOINTS.TABLET} {
    width: 136px;
    height: 54px;
  }
`;

export const Text = styled(Typography).attrs({ type: 'p3', color: 'cadetBlue' })`
  cursor: default;
  line-height: 14px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 14px;
    line-height: 20px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 16px;
    line-height: 20px;
  }
`;
