import { useState } from 'react';

import CoreModal, { useModal } from '@core/UI/Modal';
import { EModalNames } from '@core/UI/Modal/constants';
import { useAppDispatch } from '@core/store';

import { sendPollAnswers } from '../../store';
import Question from '../Question';
import Results from '../Results';

import * as UI from './PollModal.styles';
import { IPollModalProps } from './types';

const PollModal = (props: IPollModalProps) => {
  const { isPublic, onClose, onHide, poll } = props;
  const { questions } = poll;
  const dispatch = useAppDispatch();
  const modal = useModal();

  const [
    currentQuestionIndex,
    setCurrentQuestionIndex,
  ] = useState(0);

  const [answers, setAnswers] = useState<(number | string)[]>([]);

  const closeModal = () => {
    modal.close(EModalNames.POLL);
    onClose();
  };

  return (
    <CoreModal
      name={EModalNames.POLL}
      styles={UI.PollModalStyles}
      onClose={onClose}
    >
      {poll.complete
        ? (
          <Results
            poll={poll}
            onClose={closeModal}
          />
        ) : (
          <Question
            index={currentQuestionIndex}
            key={currentQuestionIndex}
            question={questions[currentQuestionIndex]}
            showIndex={questions.length > 0}
            onConfirm={(questionAnswers) => {
              const updatedAnswers = [...answers, ...questionAnswers];

              if (currentQuestionIndex === questions.length - 1) {
                dispatch(sendPollAnswers({
                  answers: updatedAnswers,
                  isPublic,
                  pollId: poll.id,
                }));
              } else {
                setAnswers(updatedAnswers);
                setCurrentQuestionIndex((index) => index + 1);
              }
            }}
            onHidePoll={() => {
              onHide(poll.id);
              closeModal();
            }}
          />
        )}

    </CoreModal>
  );
};

export default PollModal;
