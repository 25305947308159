import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import NavLink from '@core/UI/NavLink';
import { Typography } from '@core/UI/Typography';
import CloseIcon from '@core/components/Header/assets/close.svg';
import MenuIcon from '@core/components/Header/assets/menu.svg';
import { DEVICES } from '@core/constants/styles';
import { useClickOutside } from '@core/hooks/useClickOutside';
import { useMedia } from '@core/hooks/useMedia';
import { checkPermissions } from '@core/services/Auth';

import { selectUser } from '@features/authentication/store';

import * as UI from './Header.styles';
import Breadcrumbs from './components/Breadcrumbs';
import LogoutButton from './components/LogoutButton';
import { externalLinks, privateNavConfig, publicNavConfig } from './config';
import { IHeaderProps, INavLink } from './types';
import { getLink } from './utils';

const Header = (props: IHeaderProps) => {
  const { isPublic } = props;

  const navConfig = isPublic ? publicNavConfig : privateNavConfig;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMedia(DEVICES.MOBILE);
  const user = useSelector(selectUser);
  const headerRef = useRef<HTMLHeadElement | null>(null);

  useClickOutside(headerRef, () => setIsMenuOpen(false));

  const handleLinkClick = ({ onClick }: INavLink) => (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    setIsMenuOpen(false);

    onClick?.(event);
  };

  const renderNavLink = (navLink: INavLink) => {
    const { id, title, to, roles, isAnchor } = navLink;

    if (!checkPermissions(user, roles)) {
      return null;
    }

    return (
      <NavLink
        activeStyles={isAnchor ? undefined : UI.navLinkActiveStyles}
        href={getLink(to, isAnchor)}
        key={id}
        offsetScroll={isMobile ? UI.HEADER_MOBILE_HEIGHT : UI.HEADER_DESKTOP_HEIGHT}
        scrollToAnchor={isAnchor}
        styles={UI.navLinkStyles}
        onClick={handleLinkClick(navLink)}
      >
        <Typography color="softGrey" tag="span" type="h2" weight="normal">{title}</Typography>
      </NavLink>
    );
  };

  return (
    <UI.HeaderWrapper ref={headerRef}>
      <UI.HeaderContainer>
        <UI.MenuButton onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <UI.CloseIconWrapper isMenuOpen={isMenuOpen}>
            <CloseIcon />
          </UI.CloseIconWrapper>
          <UI.MenuIconWrapper isMenuOpen={isMenuOpen}>
            <MenuIcon />
          </UI.MenuIconWrapper>
        </UI.MenuButton>

        <Breadcrumbs />

        <UI.ExternalLinks>
          {externalLinks.map(({ id, Icon, href }) => (
            <UI.ExternalLink href={href} key={id}>
              {Icon}
            </UI.ExternalLink>
          ))}
        </UI.ExternalLinks>
      </UI.HeaderContainer>

      <UI.Menu isOpen={isMenuOpen}>
        <UI.MenuContent>
          <UI.PrimaryLinks>
            {navConfig.primary.map(renderNavLink)}
          </UI.PrimaryLinks>
          <UI.SecondaryLinks>
            {navConfig.secondary.map(renderNavLink)}
            {!isPublic && <LogoutButton />}
          </UI.SecondaryLinks>
        </UI.MenuContent>
      </UI.Menu>
    </UI.HeaderWrapper>
  );
};

export default Header;
