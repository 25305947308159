export const scrollIntoElementIdView = (elementId: string, offset: number = 0) => {
  try {
    const element = document.getElementById(elementId);

    if (!element) {
      throw new Error();
    }

    const { scrollY } = window;
    const { y } = element.getBoundingClientRect();

    window.scrollTo({ behavior: 'smooth', top: (scrollY + y) - offset });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Element with id: ${elementId} was not found`);
  }
};
