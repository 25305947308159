import { useState } from 'react';

import { PagePreloader } from '@eisgs/preloader';

import { Typography } from '@core/UI/Typography';
import { ROUTES } from '@core/constants/routes';
import { useToast } from '@core/hooks/useToast';
import { useLogout } from '@core/services/Auth/hooks/useLogout';

import * as UI from './LogoutButton.styles';

const LogoutButton = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const logout = useLogout();
  const toast = useToast();

  const handleLogoutClick = async () => {
    setIsLoggingOut(true);

    try {
      await logout();
      window.location.href = ROUTES.LANDING;
    } catch (_) {
      setIsLoggingOut(false);
      toast?.error('При попытке выхода возникла ошибка.');
    }
  };

  return (
    <>
      <UI.LogoutButton type="transparent" onClick={handleLogoutClick}>
        <Typography color="softGrey" tag="span" type="h2" weight="normal">Выйти</Typography>
      </UI.LogoutButton>
      {isLoggingOut && <PagePreloader hasDisabledScroll={false} />}
    </>
  );
};

export default LogoutButton;
