import styled from 'styled-components';

import { Button } from '@core/UI/Button';

export const CloseButton = styled(Button).attrs({ color: 'vividTangerine' })`
  font-size: 14px;
  line-height: 20px;
  width: max-content;
  padding: 10px 50px;
  margin-top: 24px;
`;
