import styled, { css } from 'styled-components';

import colors from '@core/constants/colors';
import { BREAKPOINTS, MAX_LAYOUT_WIDTH, PAGE_PADDINGS, Z_INDEX_HEADER } from '@core/constants/styles';
import { hexToRGB } from '@core/utils/styles';

import GovernmentSvgIcon from './assets/government.svg';
import MinstroySvgIcon from './assets/minstroy.svg';
import StroyComplexSvgIcon from './assets/stroycomplex.svg';
import { IMenuIconsProps } from './types';

export const HEADER_MOBILE_HEIGHT = 80;
export const HEADER_DESKTOP_HEIGHT = 80;

export const HeaderWrapper = styled.header`
  position: sticky;
  top: 0;
  z-index: ${Z_INDEX_HEADER};
  background: linear-gradient(270deg, #1F3C88 1.92%, ${colors.chambrayBlue} 100%);
  color: ${colors.white};
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  padding: 18px 24px 14px;
  height: ${HEADER_MOBILE_HEIGHT}px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 16px 56px;
    height: ${HEADER_DESKTOP_HEIGHT}px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    width: ${MAX_LAYOUT_WIDTH}px;
    padding: 16px ${PAGE_PADDINGS.DESKTOP}px;
  }
`;

export const MenuButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  color: ${colors.inherit};

  @media ${BREAKPOINTS.TABLET} {
    margin-right: 34px;
  }
`;

export const MenuIconWrapper = styled.div<IMenuIconsProps>`
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'none' : 'block')};
`;

export const CloseIconWrapper = styled.div<IMenuIconsProps>`
  display: ${({ isMenuOpen }) => (isMenuOpen ? 'block' : 'none')};
`;

export const ExternalLinks = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  @media ${BREAKPOINTS.TABLET} {
    gap: 20px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    gap: 38px;
  }
`;

export const ExternalLink = styled.a.attrs({ rel: 'noopener noreferrer', target: 'blank' })`
  display: flex;
`;

export const GovernmentIcon = styled(GovernmentSvgIcon)`
  width: 45px;
  height: 40px;

  @media ${BREAKPOINTS.TABLET} {
    width: 54px;
    height: 48px;
  }
`;

export const StroyComplexIcon = styled(StroyComplexSvgIcon)`
  width: 47px;
  height: 40px;

  @media ${BREAKPOINTS.TABLET} {
    width: 56px;
    height: 48px;
  }
`;

export const MinstroyIcon = styled(MinstroySvgIcon)`
  width: 36px;
  height: 40px;

  @media ${BREAKPOINTS.TABLET} {
    width: 44px;
    height: 48px;
  }
`;

export const Menu = styled.nav<{ isOpen: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: max-height 0.15s ease-in-out;
  max-height: 0;
  /* TODO */
  background: #204183;
  box-shadow: 4px 12px 30px ${hexToRGB(colors.cadetBlue, 0.12)};

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: flex;
    justify-content: center;
  }

  ${({ isOpen }) => isOpen && css`
    max-height: 100vh;
    transition: max-height 0.15s ease-in-out;
  `}
`;

export const MenuContent = styled.div`
  padding: 16px 24px;

  @media ${BREAKPOINTS.TABLET} {
    padding: 16px 56px 22px 59px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: ${MAX_LAYOUT_WIDTH}px;
    padding: 50px ${PAGE_PADDINGS.DESKTOP}px;
  }
`;

export const PrimaryLinks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.cadetBlue};

  @media ${BREAKPOINTS.TABLET} {
    row-gap: 24px;
    padding-bottom: 20px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: grid;
    flex: 1;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(3, max-content);
    grid-auto-flow: column;
    column-gap: 80px;
    row-gap: 24px;
    padding-right: 45px;
    padding-bottom: 0px;
    border-bottom: none;
    border-right: 2px solid ${colors.cadetBlue};
  }
`;

export const SecondaryLinks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 20px;

  @media ${BREAKPOINTS.TABLET} {
    row-gap: 24px;
    padding-top: 20px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    row-gap: 24px;
    padding-top: 0px;
    padding-left: 45px;
  }
`;

export const navLinkStyles = css`
  width: fit-content;
`;

export const navLinkActiveStyles = css`
  border-bottom: 2px solid ${colors.vividTangerine};
  color: ${colors.white};
`;
