import styled from 'styled-components';

import { BREAKPOINTS, MAX_LAYOUT_WIDTH, PAGE_PADDINGS } from '@core/constants/styles';

import { ILayoutStylesProps } from './types';

export const Layout = styled.div<ILayoutStylesProps>`
  padding: ${PAGE_PADDINGS.MOBILE}px;

  @media ${BREAKPOINTS.TABLET} {
    padding: ${PAGE_PADDINGS.TABLET}px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    width: ${({ fluid }) => (fluid ? '100%' : `${MAX_LAYOUT_WIDTH}px`)};
    margin: 0 auto;
  }
`;
