import { EUserRoles } from './userRoles';

export const LANDING_ANCHORS_ROUTES = {
  ANTI_CRISIS_STRATEGY: 'anti-crisis-strategy',
  MAIN_FACTORS: 'main-factors',
  NEWS: 'news',
  NATIONAL_GOALS: 'national-goals',
  INFRASTRUCTURE_MENU: 'infrastructure-menu',
  INVESTMENT_CONSTRUCTION_CYCLE: 'investment-construction-cycle',
  REGULATORY_WORK: 'regulatory-work',
  HOUSING_COMMISSIONING: 'housing-commissioning',
};

export const STRATEGY_ANCHORS_ROUTES = {
  REGULATORY_WORK: 'regulatory-work',
};

export const PUBLIC_ROUTES = {
  LANDING: '/',
  PHOTOS: '/photos',
  LOGIN: '/login',
  ENCYCLOPEDIA: '/encyclopedia',
  VIDEO_ARCHIVE: '/video-archive',
};

export const PROTECTED_ROUTES = {
  NEWS_LIST: '/news',
  NEWS_PAGE: '/news/[id]',
  HARDCODE_NEWS_PAGE: '/news/important/[id]',
  MAP: '/map',
  STRATEGY: '/strategy',
  SVETOFORS: '/svetofors',
  SVETOFOR: '/svetofors/[type]',
  SVETOFOR_DETAILING: '/svetofor_detailing/[type]',
  DASHBOARD: '/dashboard/[id]',
  NATIONAL_PROJECTS: '/national-projects',
  INFRASTRUCTURE_MENU: '/infrastructure-menu',
  INVESTMENT_CONSTRUCTION_CYCLE: '/investment-construction-cycle',
  MATERIALS: '/materials',
  MATERIALS_HEADQUARTER: '/materials/headquarter',
  MATERIALS_DISCUSSIONS: '/materials/discussions',
  MATERIALS_DISCUSSION: '/materials/discussions/[id]',
  MATERIALS_STUDY: '/materials/study',
  FEEDBACK: '/feedback',
  ADMIN_PANEL: '/admin-panel',
  ANTI_CRISIS_MONITORING: '/anti-crisis-monitoring',
  ANTI_CRISIS_MONITORING_HOUSING_POLICY: '/anti-crisis-monitoring/housing-policy',
  ANTI_CRISIS_MONITORING_MORTGAGE: '/anti-crisis-monitoring/mortgage',
  ANTI_CRISIS_MONITORING_OFFERS: '/anti-crisis-monitoring/offers',
  ANTI_CRISIS_MONITORING_DEMAND: '/anti-crisis-monitoring/demand',
  ANTI_CRISIS_MONITORING_BUILDING_MATERIALS: '/anti-crisis-monitoring/building-materials-prices',
  ANTI_CRISIS_MONITORING_SOURCES: '/anti-crisis-monitoring/sources',
  ENCYCLOPEDIA: '/encyclopedia',
  VIDEO_ARCHIVE: '/video-archive',
};

export const ROUTES_ROLES = {
  [PROTECTED_ROUTES.ADMIN_PANEL]: [EUserRoles.ADMIN, EUserRoles.INFOSEC],
  [PROTECTED_ROUTES.ANTI_CRISIS_MONITORING_OFFERS]: [EUserRoles.ADMIN, EUserRoles.INFOSEC, EUserRoles.ANTI_CRISIS_VIEWER],
  [PROTECTED_ROUTES.ANTI_CRISIS_MONITORING_DEMAND]: [EUserRoles.ADMIN, EUserRoles.INFOSEC, EUserRoles.ANTI_CRISIS_VIEWER],
  [PROTECTED_ROUTES.ANTI_CRISIS_MONITORING_SOURCES]: [EUserRoles.ADMIN, EUserRoles.INFOSEC, EUserRoles.ANTI_CRISIS_VIEWER],
};

export const ROUTES = {
  ...PUBLIC_ROUTES,
  ...PROTECTED_ROUTES,
};
