export enum EUserRoles {
  INFOSEC = 'infosec',
  ADMIN = 'admin',
  TELEGRAM = 'telegram',
  USER = 'user',
  ANTI_CRISIS_VIEWER = 'anti_crisis_viewer',
  GOVERNOR = 'governor',
}

export const UserRoles = {
  [EUserRoles.INFOSEC]: {
    name: EUserRoles.INFOSEC,
    title: 'Инфобезопасность',
  },
  [EUserRoles.ADMIN]: {
    name: EUserRoles.ADMIN,
    title: 'Админ',
  },
  [EUserRoles.TELEGRAM]: {
    name: EUserRoles.TELEGRAM,
    title: 'Телеграм-бот',
  },
  [EUserRoles.USER]: {
    name: EUserRoles.USER,
    title: 'Пользователь',
  },
  [EUserRoles.GOVERNOR]: {
    name: EUserRoles.GOVERNOR,
    title: 'Губернатор',
  },
  [EUserRoles.ANTI_CRISIS_VIEWER]: {
    name: EUserRoles.ANTI_CRISIS_VIEWER,
    title: 'Антикризисный мониторинг',
  },
};
