import { Typography } from '@core/UI/Typography';

import * as UI from './ResultsChart.styles';
import { IResultsChartProps } from './types';

const ResultsChart = (props: IResultsChartProps) => {
  const { poll: { questions }, styles } = props;

  return (
    <UI.Container styles={styles}>
      {questions.map(({ id, multiVotes, text, variants }) => (
        <UI.Question key={id}>
          <Typography type="h3">
            {text}
          </Typography>
          <Typography color="manatee" type="p1">
            {
              multiVotes
                ? 'Выберите несколько вариантов ответа'
                : 'Выберите 1 вариант ответа'
            }
          </Typography>

          <UI.Variants>
            {variants.map((variant) => (
              <UI.Variant key={variant.id}>
                <Typography type="p2" weight="bold">
                  {`${variant.percent}%`}
                </Typography>
                <Typography type="p2">
                  {variant.text}
                </Typography>
                <UI.Percentage percent={variant.percent} />
              </UI.Variant>
            ))}
          </UI.Variants>
        </UI.Question>
      ))}
    </UI.Container>
  );
};

export default ResultsChart;
