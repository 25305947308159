import { LINKS } from '@core/constants/links';
import { LANDING_ANCHORS_ROUTES, ROUTES, ROUTES_ROLES } from '@core/constants/routes';

import * as UI from './Header.styles';
import { PAGES_TITLES } from './constants';
import { INavConfig } from './types';

export const externalLinks = [
  {
    id: 1,
    Icon: <UI.GovernmentIcon />,
    href: LINKS.GOVERNMENT,
  },
  {
    id: 2,
    Icon: <UI.StroyComplexIcon />,
    href: LINKS.STROYCOMPLEX,
  },
  {
    id: 3,
    Icon: <UI.MinstroyIcon />,
    href: LINKS.MINSTROY,
  },
];

export const privateNavConfig: INavConfig = {
  primary: [
    { id: 1, title: PAGES_TITLES.NEWS_LIST, to: ROUTES.NEWS_LIST },
    { id: 2, title: PAGES_TITLES.MAP, to: ROUTES.MAP },
    { id: 3, title: PAGES_TITLES.STRATEGY, to: ROUTES.STRATEGY },
    { id: 4, title: PAGES_TITLES.SVETOFORS, to: ROUTES.SVETOFORS },
    { id: 5, title: PAGES_TITLES.NATIONAL_PROJECTS, to: ROUTES.NATIONAL_PROJECTS },
    // Скрыто в задаче ERS-26223
    // { id: 6, title: PAGES_TITLES.INFRASTRUCTURE_MENU, to: ROUTES.INFRASTRUCTURE_MENU },
    { id: 7, title: PAGES_TITLES.INVESTMENT_CONSTRUCTION_CYCLE, to: ROUTES.INVESTMENT_CONSTRUCTION_CYCLE },
    { id: 8, title: PAGES_TITLES.MATERIALS, to: ROUTES.MATERIALS_HEADQUARTER },
    {
      id: 9,
      title: PAGES_TITLES.ANTI_CRISIS_MONITORING,
      to: ROUTES.ANTI_CRISIS_MONITORING,
      roles: ROUTES_ROLES[ROUTES.ANTI_CRISIS_MONITORING],
    },
    { id: 10, title: PAGES_TITLES.ENCYCLOPEDIA, to: ROUTES.ENCYCLOPEDIA },
    { id: 11, title: PAGES_TITLES.VIDEO_ARCHIVE, to: ROUTES.VIDEO_ARCHIVE },
  ],
  secondary: [
    { id: 1, title: PAGES_TITLES.FEEDBACK, to: ROUTES.FEEDBACK },
    { id: 2, title: PAGES_TITLES.ADMIN_PANEL, to: ROUTES.ADMIN_PANEL, roles: ROUTES_ROLES[ROUTES.ADMIN_PANEL] },
  ],
};

export const publicNavConfig: INavConfig = {
  primary: [
    { id: 0, title: 'Главное', to: LANDING_ANCHORS_ROUTES.ANTI_CRISIS_STRATEGY, isAnchor: true },
    { id: 1, title: 'Основные показатели', to: LANDING_ANCHORS_ROUTES.MAIN_FACTORS, isAnchor: true },
    { id: 2, title: 'Новости', to: LANDING_ANCHORS_ROUTES.NEWS, isAnchor: true },
    { id: 3, title: 'Национальная цель', to: LANDING_ANCHORS_ROUTES.NATIONAL_GOALS, isAnchor: true },
    // Скрыто в задаче ERS-36549
    // { id: 4, title: 'Инфраструктурное меню', to: LANDING_ANCHORS_ROUTES.INFRASTRUCTURE_MENU, isAnchor: true },
    { id: 5, title: 'Инвестиционно-строительный цикл', to: LANDING_ANCHORS_ROUTES.INVESTMENT_CONSTRUCTION_CYCLE, isAnchor: true },
    { id: 6, title: 'Нормативно-правовая работа', to: LANDING_ANCHORS_ROUTES.REGULATORY_WORK, isAnchor: true },
    { id: 7, title: 'Ввод жилья', to: LANDING_ANCHORS_ROUTES.HOUSING_COMMISSIONING, isAnchor: true },
    { id: 8, title: PAGES_TITLES.ENCYCLOPEDIA, to: ROUTES.ENCYCLOPEDIA },
    { id: 9, title: PAGES_TITLES.VIDEO_ARCHIVE, to: ROUTES.VIDEO_ARCHIVE },
  ],
  secondary: [
    { id: 0, title: PAGES_TITLES.LOGIN, to: ROUTES.LOGIN },
  ],
};
