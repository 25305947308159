import styled, { css } from 'styled-components';

import colors from '@core/constants/colors';
import { hexToRGB } from '@core/utils/styles';

import { IButtonStylesProps } from './types';

export const Button = styled.button<IButtonStylesProps>`
  position: relative;
  border: none;
  outline: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ $loading }) => $loading && css`
    *:hover {
      cursor: default;
    }
  `};
`;

export const ButtonFilled = styled(Button)`
  min-height: 40px;
  min-width: 125px;
  border-radius: 4px;
  background-color: ${({ color }) => (color ? colors[color] : colors.chambrayBlue)};
  color: ${colors.white};

  ${({ disabled }) => disabled && css`
    background-color: ${colors.mystic};

    *:hover {
      cursor: default;
    }
  `};
`;

export const ButtonTransparent = styled(Button)`
  background-color: transparent;
`;

export const ButtonSecondary = styled(Button)`
  padding: 12px 20px;
  line-height: 16px;
  border: 1px solid ${colors.mystic};
  border-radius: 4px;
  background-color: transparent;
  color: ${colors.riverBlue};
`;

export const RightSide = styled.span`
  display: flex;
  align-items: center;
  margin-left: 7px;
`;

export const ButtonPrimary = styled(Button)`
  padding: 10px 16px;
  color: ${colors.chambrayBlue};
  background-color: ${colors.mystic};
  transition: background-color 0.2s;
  border-radius: 4px;

  &:hover {
    background-color: ${hexToRGB(colors.mystic, 0.8)};
  }
`;
