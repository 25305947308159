import { useEffect } from 'react';

import * as UI from './Layout.styles';
import { ILayoutProps } from './types';

export const Layout = (props: ILayoutProps) => {
  const {
    id,
    children,
    className,
    fluid = false,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <UI.Layout
      className={className}
      fluid={fluid}
      id={id}
    >
      {children}
    </UI.Layout>
  );
};
