import { ReactNode } from 'react';

// import DomrfIcon from '@core/components/Footer/assets/domrf.svg';
import GovernmentRfIcon from '@core/components/Footer/assets/government-rf.svg';
import MinstroyIcon from '@core/components/Footer/assets/minstroy.svg';
import MintranceIcon from '@core/components/Footer/assets/mintrance.svg';
import SafeRoadIcon from '@core/components/Footer/assets/npr-safe-roads.svg';
import TrunkPlanIcon from '@core/components/Footer/assets/npr-trunk-plan.svg';
import UrbanEnvironmentIcon from '@core/components/Footer/assets/npr-urban-environment.svg';
import StroyComplexIcon from '@core/components/Footer/assets/stroycomplex.svg';
import { LINKS } from '@core/constants/links';

interface INav {
  id: number;
  Icon: ReactNode;
  href: string;
}

export const navConfig: INav[] = [
  {
    id: 1,
    Icon: <GovernmentRfIcon />,
    href: LINKS.GOVERNMENT,
  }, {
    id: 2,
    Icon: <StroyComplexIcon />,
    href: '',
  }, {
    id: 3,
    Icon: <MinstroyIcon />,
    href: LINKS.MINSTROY,
  }, {
    id: 4,
    Icon: <MintranceIcon />,
    href: LINKS.MINTRANS,
  },
  // ERS-28560: Иконка ДОМ.РФ временно скрыта
  // {
  //   id: 5,
  //   Icon: <DomrfIcon />,
  //   href: 'https://xn--80az8a.xn--d1aqf.xn--p1ai/',
  // },
  {
    id: 6,
    Icon: <SafeRoadIcon />,
    href: LINKS.NP_SAFE_ROADS,
  }, {
    id: 7,
    Icon: <UrbanEnvironmentIcon />,
    href: LINKS.NP_URBAN_ENVIRONMENT,
  }, {
    id: 8,
    Icon: <TrunkPlanIcon />,
    href: LINKS.NP_MAGISTRAL_PLAN,
  },
];
