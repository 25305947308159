import { Modal as ModalLib, useModal } from '@eisgs/modal';

import { IModalProps } from '@core/UI/Modal/types';

import * as UI from './Modal.styles';

const Modal = (props: IModalProps) => {
  const { styles, unblockOnUnmount = true } = props;
  return (
    <ModalLib
      {...props}
      styles={styles || UI.ModalStyles}
      unblockOnUnmount={unblockOnUnmount}
    />
  );
};

export { useModal };

export default Modal;
