import { css } from 'styled-components';

import { BREAKPOINTS, PAGE_PADDINGS, Z_INDEX_POPUP } from '@core/constants/styles';

import { Content } from './components/Poll/Poll.styles';

const POLL_HEIGHT = 96;
const GAP_BETWEEN_POLLS = 8;

const getUnfoldedStyles = (index: number) => css`
  padding-bottom: ${index === 0 ? 0 : '8px'};
  bottom: calc(${PAGE_PADDINGS.MOBILE}px + ${index * POLL_HEIGHT + (index > 0 ? index - 1 : 0) * GAP_BETWEEN_POLLS}px);
  right: ${PAGE_PADDINGS.MOBILE}px;
    
  @media ${BREAKPOINTS.TABLET} {
    bottom: calc(${PAGE_PADDINGS.TABLET}px + ${index * POLL_HEIGHT + (index > 0 ? index - 1 : 0) * GAP_BETWEEN_POLLS}px);
    right: calc(${PAGE_PADDINGS.TABLET}px);
  }
`;

const getFoldedStyles = (index: number, pollsCount: number) => {
  const displayIndex = index > 2 ? 2 : index;

  return css`
    bottom: calc(${PAGE_PADDINGS.MOBILE}px + ${displayIndex * 12}px);
    right: calc(${PAGE_PADDINGS.MOBILE + (pollsCount === 1 ? 0 : 8)}px - ${displayIndex * 8}px);

    @media ${BREAKPOINTS.TABLET} {
      bottom: calc(${PAGE_PADDINGS.TABLET}px + ${displayIndex * 12}px);
      right: calc(${PAGE_PADDINGS.TABLET}px - ${displayIndex * 10}px);
    }

    ${Content} {
      box-shadow: ${index > 2 && 'none'};
    }
  `;
};

export const getPollStyles = ({
  isUnfolded,
  index,
  pollsCount,
}: {
  isUnfolded: boolean;
  index: number;
  pollsCount: number;
}) => css`
  position: fixed;
  z-index: ${Z_INDEX_POPUP + pollsCount - index};

  @media ${BREAKPOINTS.TABLET} {
    width: 383px;
  }

  ${isUnfolded ? getUnfoldedStyles(index) : getFoldedStyles(index, pollsCount)}
`;
