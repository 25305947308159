import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@core/UI/Button';
import { Typography } from '@core/UI/Typography';

import { selectPolls } from '../../store';

import * as UI from './Question.styles';
import { IQuestionProps } from './types';

const Question = (props: IQuestionProps) => {
  const {
    question: {
      multiVotes,
      text,
      variants,
    },
    index,
    onConfirm,
    onHidePoll,
    showIndex,
  } = props;

  const { meta: { fetchLoading, fetchError } } = useSelector(selectPolls);

  const [answers, setAnswers] = useState<(string | number)[]>([]);

  return (
    <div>
      <UI.Header>
        {showIndex ? `${index + 1}. ${text}` : text}
      </UI.Header>
      <Typography color="manatee" type="p1">
        {
          multiVotes
            ? 'Выберите несколько вариантов ответа'
            : 'Выберите 1 вариант ответа'
        }
      </Typography>

      {multiVotes
        ? (
          <UI.CheckboxGroup>
            {variants.map((variant) => (
              <UI.Checkbox
                checked={answers.includes(variant.id)}
                key={variant.id}
                onChange={
                  (checked) => setAnswers(checked
                    ? [...answers, variant.id]
                    : answers.filter((answer) => answer !== variant.id))
                }
              >
                {variant.text}
              </UI.Checkbox>
            ))}
          </UI.CheckboxGroup>
        ) : (
          <UI.Radio
            direction="vertical"
            options={variants.map((variant) => ({
              id: variant.id,
              description: variant.text,
              code: String(variant.id),
            }))}
            value={answers[0]}
            onChange={(e) => {
              setAnswers([e.target.value]);
            }}
          />
        )}

      {fetchError && (
        <UI.ErrorMessage>
          <strong>Внимание!</strong>
          {' '}
          Произошла ошибка при отправке ответа.
        </UI.ErrorMessage>
      )}

      <UI.Controls>
        <Button
          color="vividTangerine"
          disabled={!answers.length}
          loading={fetchLoading}
          onClick={() => onConfirm(answers)}
        >
          Ответить
        </Button>
        <UI.HidePollButton
          onClick={onHidePoll}
        >
          Больше не показывать
        </UI.HidePollButton>
      </UI.Controls>
    </div>
  );
};

export default Question;
