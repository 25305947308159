import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@core/store';

import { fetchPolls, selectPolls } from './store';
import { getCompletedPublicPolls, getHiddenPolls, persistHiddenPoll } from './utils';

export function usePolls(isPublic?: boolean) {
  const dispatch = useAppDispatch();
  const { data } = useSelector(selectPolls);

  const [closedPolls, setClosedPolls] = useState<number[]>([]);
  const [hiddenPolls, setHiddenPolls] = useState<number[]>(getHiddenPolls(isPublic));
  const completedPublicPolls = useMemo(() => getCompletedPublicPolls(), []);

  useEffect(() => {
    dispatch(fetchPolls({ isPublic }));
  }, [isPublic, dispatch]);

  const polls = useMemo(() => {
    if (!data) {
      return null;
    }

    const visiblePolls = data.filter(
      (poll) => !hiddenPolls.includes(poll.id) && !closedPolls.includes(poll.id),
    );

    if (!isPublic) {
      return visiblePolls;
    }

    return visiblePolls.map(
      (poll) => (completedPublicPolls.includes(poll.id)
        ? { ...poll, complete: true }
        : poll),
    );
  }, [data, isPublic, hiddenPolls, completedPublicPolls, closedPolls]);

  return useMemo(() => ({
    onClosePoll: (pollId: number) => {
      setClosedPolls((currClosedPolls) => [...currClosedPolls, pollId]);
    },
    onHidePoll: (pollId: number) => {
      setHiddenPolls((currHiddenPolls) => [...currHiddenPolls, pollId]);
      persistHiddenPoll(pollId, isPublic);
    },
    polls,
  }), [polls, isPublic]);
}
