import styled from 'styled-components';

import colors from '@core/constants/colors';

import { TTypographyType, TTypographyWeight, TLineHeight, ITypographyProps } from './types';

const getTagName = (type: ITypographyProps['type'], tag: ITypographyProps['tag']): string => {
  if (tag) {
    return tag;
  }

  switch (type) {
    case 'h1':
    case 'h2':
    case 'h3':
      return type;
    default:
      return 'p';
  }
};

const getFontSize = (type: TTypographyType) => {
  switch (type) {
    case 'h1':
      return 28;
    case 'h2':
      return 24;
    case 'h3':
      return 18;
    case 'p1':
      return 16;
    case 'p2':
      return 14;
    case 'p3':
    default:
      return 12;
  }
};

const getFontWeight = (type: TTypographyType, weight?: TTypographyWeight): TTypographyWeight => {
  if (weight) {
    return weight;
  }

  switch (type) {
    case 'h1':
    case 'h2':
    case 'h3':
      return 'bold';
    case 'p1':
    case 'p2':
    case 'p3':
    default:
      return 'normal';
  }
};

const getLineHeight = (type: TTypographyType, lineHeight?: TLineHeight): string => {
  if (lineHeight) {
    return typeof lineHeight === 'number' ? `${lineHeight}px` : lineHeight;
  }

  switch (type) {
    case 'h1':
      return '36px';
    case 'h2':
      return '32px';
    case 'h3':
    case 'p1':
      return '24px';
    case 'p2':
      return '20px';
    case 'p3':
    default:
      return '16px';
  }
};

const getEllipsisStyles = (ellipsisMaxLines: number = 0) => {
  switch (true) {
    case ellipsisMaxLines === 1:
      return `
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      `;
    case ellipsisMaxLines > 1:
      return `
        display: -moz-box;
        display: -webkit-box;
        -webkit-line-clamp: ${ellipsisMaxLines};
        -webkit-box-orient: vertical;
        overflow: hidden;
      `;
    default:
      return '';
  }
};

export const Typography = styled('p')
  .withConfig({ shouldForwardProp: (prop, defaultValidatorFn) => ['children'].includes(prop) && defaultValidatorFn(prop) })
  .attrs(({ type, tag }: ITypographyProps) => ({ as: getTagName(type, tag) }))
<ITypographyProps>`
  font-size: ${({ type }) => getFontSize(type)}px;
  font-weight: ${({ type, weight }) => getFontWeight(type, weight)};
  line-height: ${({ type, lineHeight }) => getLineHeight(type, lineHeight)};

  color: ${({ color }) => (color ? colors[color] : colors.riverBlue)};
  text-transform: ${(({ transform }) => (transform || 'none'))};

  ${({ ellipsisMaxLines }) => getEllipsisStyles(ellipsisMaxLines)}
  ${({ styles }) => (styles || undefined)}
`;
