export const EModalNames = {
  NEWS_CREATE_UPDATE: 'newsCreateUpdate',
  NEWS_DELETE: 'newsDelete',
  FILES_UPLOAD: 'filesUpload',
  FILE_EDIT: 'fileEdit',
  FILE_DELETE: 'fileDelete',
  POLL: 'poll',
  PREVIEW_FILE: 'previewFile',
  UPLOAD_USERS: 'uploadUsers',
  BLOCK_USER: 'blockUser',
  EDIT_USER: 'editUser',
  ANTI_CRISIS_VIDEO: 'antiCrisisVideo',
  ANTI_CRISIS_MESSAGE: 'antiCrisisMessage',
  EDIT_DISCUSSION: 'editDiscussion',
  DELETE_DISCUSSION: 'deleteDiscussion',
  MOBILE_SORTING_DISCUSSION: 'mobileSortingDiscussion',
  EDIT_DISCUSSION_COMMENT: 'editDiscussionComment',
  DELETE_DISCUSSION_COMMENT: 'deleteDiscussionComment',
  LANDING_NEWS: 'landingNews',
  ALBUM: 'album',
  INFRAMENU_REGION_SELECTOR: 'inframenuRegionSelector',
  REGIONS_MODAL: 'regions',
  GOVERNOR_MODAL: 'governorModal',
  DELETE_MEDIA_ITEMS: 'deleteMediaItems',
  ENCYCLOPEDIA_INFO: 'encyclopediaInfo',
  DELETE_POLL: 'deletePoll',
  VIDEO: 'video',
};
