import { Typography } from '@core/UI/Typography';

import * as UI from './Footer.styles';
import { navConfig } from './navConfig';

const Footer = () => (
  <UI.FooterWrapper>
    <UI.FooterContainer>
      <UI.LinksContainer>
        {navConfig.map(({ id, Icon, href }) => (
          <UI.ExternalLink href={href} key={id} rel="noreferrer" target="_blank">
            {Icon}
          </UI.ExternalLink>
        ))}
      </UI.LinksContainer>

      <UI.Contacts>
        <UI.PhoneNumber href="tel:8 800 755-55-00">
          <Typography color="cadetBlue" type="h3">
            8 800 755-55-00
          </Typography>
        </UI.PhoneNumber>
        <UI.Email href="mailto:nash@domrf.ru">
          <Typography color="cadetBlue" type="p1">
            nash@domrf.ru
          </Typography>
        </UI.Email>
      </UI.Contacts>
    </UI.FooterContainer>
  </UI.FooterWrapper>
);

export default Footer;
