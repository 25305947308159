import * as UI from './Radio.styles';
import { IRadioProps, TObject } from './types';

const Radio = <T extends TObject>(props: IRadioProps<T>) => {
  const {
    label,
    options = [],
    name,
    onChange,
    value,
    error,
    disabled = false,
    direction = 'horizontal',
    className,
  } = props;

  return (
    <UI.Wrapper className={className} disabled={disabled}>
      {label && (
        <UI.RadioHeadingLabel>
          {label}
        </UI.RadioHeadingLabel>
      )}

      <UI.RadiosContainer isVertical={direction !== 'horizontal'}>
        {options.map((option) => {
          const isChecked = value === option.code;
          return (
            <UI.RadioLabel key={option.id}>
              <UI.RadioInput
                checked={isChecked}
                disabled={disabled}
                id={option.id}
                name={name}
                type="radio"
                value={option.code}
                onChange={onChange}
              />

              <UI.RadioCircle />

              <UI.RadioText>
                {option.description}
              </UI.RadioText>
            </UI.RadioLabel>
          );
        })}
      </UI.RadiosContainer>

      {error && (
        <UI.ErrorMessage>
          {error}
        </UI.ErrorMessage>
      )}
    </UI.Wrapper>
  );
};

export default Radio;
