export type TColors =
  | 'inherit'
  | 'prussianBlue'
  | 'riverBlue'
  | 'chambrayBlue'
  | 'cadetBlue'
  | 'blue'
  | 'mystic'
  | 'white'
  | 'vividTangerine'
  | 'black'
  | 'mainBlack'
  | 'grey'
  | 'darkGrey'
  | 'lightGrey'
  | 'softGrey'
  | 'bloodRed'
  | 'lightRed'
  | 'negativeRed'
  | 'aliceBlue'
  | 'aquaHaze'
  | 'forestGreen'
  | 'lightGreen'
  | 'green'
  | 'manatee'
  | 'tangerineYellow'
  | 'yellow'
  | 'darkOrange';

const colors: Record<TColors, string> = {
  inherit: 'inherit',
  prussianBlue: '#002950',
  riverBlue: '#404756',
  chambrayBlue: '#325C92',
  cadetBlue: '#B3B9C9',
  blue: '#4286C6',
  mystic: '#D7E2E9',
  white: '#FFFFFF',
  vividTangerine: '#FF9D79',
  black: '#000000',
  mainBlack: '#1C1B28',
  grey: '#9EA7AD',
  darkGrey: '#686A7A',
  lightGrey: '#F9FAFC',
  softGrey: '#E9ECF0',
  bloodRed: '#D9303A',
  lightRed: '#F4B9B9',
  negativeRed: '#F8445A',
  aliceBlue: '#F2F6F9',
  aquaHaze: '#F2F6FA',
  forestGreen: '#239D3E',
  lightGreen: '#AED679',
  green: '#7AAB3A',
  darkOrange: '#E5AE8C',
  manatee: '#868A96',
  tangerineYellow: '#FDC911',
  yellow: '#F8D05F',
};

export default colors;
