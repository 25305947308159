import * as UI from './Poll.styles';
import { IPollProps } from './types';

const Poll = (props: IPollProps) => {
  const { onClick, onClose, poll, styles } = props;

  return (
    <UI.Container
      styles={styles}
    >
      <UI.Content>
        <UI.PollIcon />

        <UI.TextContent>
          <UI.PollName>
            {poll.name}
          </UI.PollName>

          <UI.ButtonLink
            onClick={() => onClick(poll.id)}
          >
            {poll.complete ? 'Посмотреть результаты опроса' : 'Пройти опрос'}
          </UI.ButtonLink>
        </UI.TextContent>

        <UI.CloseIcon
          onClick={() => onClose(poll.id)}
        />
      </UI.Content>
    </UI.Container>
  );
};

export default Poll;
