import { memo } from 'react';

import { LINKS } from '@core/constants/links';

import * as UI from './FooterPublic.styles';

const FooterPublic = () => (
  <UI.Wrapper>
    <UI.InnerContainer>
      <UI.MinstroyLogo
        href={LINKS.MINSTROY}
        rel="noopener noreferrer"
        target="_blank"
      />
      <UI.Text>
        Поддержка и развитие портала осуществляется Министерством строительства и&nbsp;жилищно‑коммунального хозяйства Российской Федерации
      </UI.Text>
    </UI.InnerContainer>
  </UI.Wrapper>
);

export default memo(FooterPublic);
