import { useMemo, useState } from 'react';

import { useModal } from '@eisgs/modal';

import { EModalNames } from '@core/UI/Modal/constants';

import Poll from './components/Poll';
import PollModal from './components/PollModal';
import { usePolls } from './hooks';
import * as UI from './styles';
import { IPollsProps } from './types';

const Polls = (props: IPollsProps) => {
  const { isPublic } = props;
  const modal = useModal();

  const { polls, onHidePoll, onClosePoll } = usePolls(isPublic);

  const [activePollId, setActivePollId] = useState<number | null>(null);
  const [isUnfolded, setIsUnfolded] = useState<boolean>(false);

  const activePoll = useMemo(
    () => (
      polls && activePollId
        ? polls.find((poll) => poll.id === activePollId)
        : null
    ),
    [activePollId, polls],
  );

  if (!polls) {
    return null;
  }

  return (
    <>
      <div
        onClick={() => setIsUnfolded(true)}
        onMouseEnter={() => setIsUnfolded(true)}
        onMouseLeave={() => setIsUnfolded(false)}
      >
        {polls.map((poll, index) => (
          <Poll
            key={poll.id}
            poll={poll}
            styles={UI.getPollStyles({
              index,
              pollsCount: polls.length,
              isUnfolded,
            })}
            onClick={(pollId) => {
              setActivePollId(pollId);
              modal.open(EModalNames.POLL);
            }}
            onClose={onClosePoll}
          />
        ))}
      </div>

      {activePoll && (
        <PollModal
          isPublic={isPublic}
          poll={activePoll}
          onClose={() => setActivePollId(null)}
          onHide={onHidePoll}
        />
      ) }
    </>
  );
};

export default Polls;
